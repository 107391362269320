import { HubConnectionBuilder } from '@microsoft/signalr';
import Picker from "emoji-picker-react";
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import FileBase64 from 'react-file-base64';
import { FaUpload } from 'react-icons/fa';
import { FiSmile } from 'react-icons/fi';
import { MdSend } from "react-icons/md";
import { RiAdminFill, RiAdminLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import { AttachmentBtn, RemoveCircle } from '../../Components/ActionBtns';
import { CallAxios } from '../../Helpers';
import { config } from '../../Helpers/Config';
import { CircleBtnWithIcon } from '../../Helpers/Global/StyledComponents';
import { setmessgaeCounter } from '../../Store/slices/AppSlice';
import { toast } from 'react-toastify';

function Inbox() {
  const user = useSelector(x => x.app.user);
  const dispatch = useDispatch();
  const fileInput = useRef();
  const PickerDropDown = { right: "15px !important", top: "45px !important" };

  const [ChatList, setChatList] = useState(null);
  const [ActiveChat, setActiveChat] = useState(null);
  const [ActiveChatMessagesList, setActiveChatMessagesList] = useState([]);
  const [Message, setMessage] = useState("");
  const [connection, setConnection] = useState(null);
  const [newreceivedmessage, setNewreceivedmessage] = useState(null);
  const [newChatCreated, setnewChatCreated] = useState(null);
  const [CloseChatTrigger, setCloseChatTrigger] = useState(null);
  const submitBtnRef = useRef();
  const [hideShowEmojipicker, sethideShowEmojipicker] = useState(false);
  const [setInboxHeight, setsetInboxHeight] = useState(null);
  const chatpanal = useRef();
  const chatpanalHead = useRef();
  const chatpanalFoot = useRef();

  // const [DeactiveUserID, setDeactiveUserID] = useState(null);
  // const [ActiveUserID, setActiveUserID] = useState(null);
  useEffect(() => {
    if (!!user.LoginUserId) {
      onLoad()
    }
  }, [user.LoginUserId]);

  const onLoad = async () => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(config.API_URL + '/supportChatHub', { accessTokenFactory: () => localStorage.getItem('token') })
      .withAutomaticReconnect()
      .build();
    newConnection.start()
      .then(result => {
        newConnection.on("unread_messgae_counter", (data) => {
          dispatch(
            setmessgaeCounter(data)
          )
        });
        newConnection.on("close_chat_trigger", function (data) {
          setCloseChatTrigger(data)
        });
        newConnection.on("get_assign_user_chat", (data) => {
          setChatList(data || null)
        });
        newConnection.on("receive_messgae", (data) => {
          setNewreceivedmessage(data)
        });
        newConnection.on("get_chat_list", (data) => {
          setActiveChatMessagesList(data.messages);
        });
        newConnection.on("new_supportchat_created", (data) => {
          setnewChatCreated(data)
        });
        // newConnection.on("deactive_user", (id) => {
        //   setDeactiveUserID(id)
        // });
        // newConnection.on("active_user", (id) => {
        //   setActiveUserID(id)
        // });
        newConnection.invoke("GetAssignChat", { id: user.LoginUserId })
      })
      .catch(e => console.log('Connection failed: ', e));

    setConnection(newConnection);
  }
  useEffect(() => {
    if (!!newreceivedmessage) {
      if (!!ChatList) {
        var getChat = ChatList;
        // var chat = getChatList.find(x => x.supportChatId === newreceivedmessage.supportChatId);
        if (newreceivedmessage.message.isAttachment) {
          getChat = { ...getChat, isBold: true, lastMessageDateTime: new Date(newreceivedmessage.dateTime) }
        } else {
          getChat = { ...getChat, isBold: true, lastMessage: newreceivedmessage.message.message, lastMessageDateTime: new Date(newreceivedmessage.dateTime) }
        }
        // var list = ChatList;
        // getChatList[getChatList.findIndex(x => x.supportChatId === newreceivedmessage.supportChatId)] = chat;
        // setChatList(getChatList.sort((a, b) => new Date(b.lastMessageDateTime) - new Date(a.lastMessageDateTime)))
        setChatList(getChat)
        if (!!getChat && !!ActiveChat && ActiveChat.supportChatId === newreceivedmessage.supportChatId) {
          var messageList = [...ActiveChatMessagesList];
          messageList.push(newreceivedmessage.message)
          setActiveChatMessagesList(messageList)
        }
      }
    }
  }, [newreceivedmessage]);

  useEffect(() => {
    if (ActiveChat?.supportChatId === CloseChatTrigger || ChatList?.supportChatId === CloseChatTrigger) {
      setActiveChat(null)
      setChatList(null)
      setActiveChatMessagesList([])
      if (connection.state === "Connected") {
        connection.invoke("UnreadMessgaeCounter", user.LoginUserId.toString())
      } else {
        onLoad()
        connection.invoke("UnreadMessgaeCounter", user.LoginUserId.toString())
      }
    }
  }, [CloseChatTrigger]);

  // useEffect(() => {
  //   var chat = ChatList.find(x => x.senderId === ActiveUserID);

  //   if (!!ActiveUserID && !!chat) {
  //     let prev = [...ChatList];
  //     var chat = prev.find(x => x.senderId === ActiveUserID);
  //     chat = { ...chat, isActive: true }
  //     var list = prev;
  //     list[prev.findIndex(x => x.senderId === ActiveUserID)] = chat;
  //     setChatList(list)
  //   }
  // }, [ActiveUserID]);
  // useEffect(() => {
  //   var chat = ChatList.find(x => x.senderId === DeactiveUserID);

  //   if (!!DeactiveUserID && !!chat) {
  //     let prev = [...ChatList];
  //     var chat = prev.find(x => x.senderId === DeactiveUserID);
  //     chat = { ...chat, isActive: false }
  //     var list = prev;
  //     list[prev.findIndex(x => x.senderId === DeactiveUserID)] = chat;
  //     setChatList(list)
  //   }
  // }, [DeactiveUserID]);
  useEffect(() => {
    if (!!newChatCreated) {
      // var list = ChatList;
      // list.push(newChatCreated)

      setChatList(newChatCreated)
      if (connection.state === "Connected") {
        connection.invoke("UnreadMessgaeCounter", user.LoginUserId.toString())
      } else {
        onLoad()
        connection.invoke("UnreadMessgaeCounter", user.LoginUserId.toString())
      }
    }
  }, [newChatCreated]);
  function timeSince(date) {
    // return moment.utc(date).local().startOf('seconds').fromNow()
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return "a few seconds ago";
  }
  const fnLoginasAdmin = async (id) => {
    var response = await CallAxios('api/AdminPanel/Company/LoginAsCompanyAdminById|post', { id: id.toString() })
    if (!!response && response.status === 200) {
      window.open(config.WEB_URL + '/adminlogin?code=' + encodeURIComponent(response.data), '_blank');
    }
  }
  const SendMessage = async (e) => {
    e.preventDefault();
    if (!!Message) {
      var data = {
        SupportChatId: ActiveChat.supportChatId,
        SenderId: parseInt(ActiveChat.senderId),
        Message: Message,
        SupportChatUserId: parseInt(user.LoginUserId),
        IsFromSupportTeam: true,
        IsAttachment: false,

      }
      if (connection.state === "Connected") {
        connection.invoke('SendMessage', data)
        setMessage("");
      } else {
        await onLoad()
        connection.invoke('SendMessage', data)
        setMessage("");
      }
    }
  }

  useEffect(() => {
    if (!!ActiveChatMessagesList && ActiveChatMessagesList.length > 0) {
      scrollMsg()

      if (ChatList.supportChatId === ActiveChat?.supportChatId) {
        var getChat = ChatList;
        // var chat = getChatList.supportChatId === ActiveChat.supportChatId ?;
        getChat = { ...getChat, isBold: false }
        // getChatList[getChatList.findIndex(x => x.supportChatId === ActiveChat.supportChatId)] = chat;
        setChatList(getChat)
      }




      if (ActiveChatMessagesList.some(x => x.isSeen === false)) {
        if (connection.state === "Connected") {
          connection.invoke('Seen', ActiveChat.supportChatId)
        } else {
          onLoad()
          connection.invoke('Seen', ActiveChat.supportChatId)

        }
      }
    }
  }, [ActiveChatMessagesList]);
  const scrollMsg = () => {
    const chatItems = document.querySelector('.msg-box').children;
    if (chatItems.length > 0) {
      chatItems[chatItems.length - 1].scrollIntoView(true);
    }
  }
  const EndChat = async () => {
    if (connection.state === "Connected") {
      connection.invoke('CloseChat', { id: ActiveChat.supportChatId.toString() })
    }
    else {
      await onLoad()
      connection.invoke('CloseChat', { id: ActiveChat.supportChatId.toString() })
    }
  }
  const HandleChatClick = async (senderId) => {
    if (connection.state === "Connected") {
      connection.invoke('GetChat', { id: senderId })
    }
    else {
      await onLoad()
      connection.invoke('GetChat', { id: senderId })
    }
  }
  const EmojiHandleClick = (n, e) => {
    setMessage(Message + n.emoji)
  };

  const UploadFile = async (files) => {
    if (files.length > 0) {
      if (!files.some(x => x.type !== "image/jpeg" && x.type !== "image/png" && x.type !== "application/pdf" && x.type !== "application/vnd.ms-excel" &&
        x.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && x.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        && x.type !== "text/csv") && !files.some(x => x.file.size / (1024 ** 2) > 2)) {
        var data = {
          Attachments: files.map(x => x.file)
        }
        var form_data = new FormData();
        Object.keys(data).forEach((key, index) => {
          if (typeof (data[key]) === "object" && !!data[key] && data[key].length > 0) {
            for (var i = 0; i < data[key].length; i++) {
              form_data.append(key + [], data[key][i]);
            }
          } else if (data[key]) {
            form_data.append(key, data[key]);
          }
        })

        var res = await CallAxios("api/Documents/UploadFiles|post", form_data)
        if (!!res && res.data.length > 0) {
          var obj = {
            SupportChatId: ActiveChat.supportChatId,
            SenderId: parseInt(ActiveChat.senderId),
            SupportChatUserId: parseInt(user.LoginUserId),
            IsFromSupportTeam: true,
            IsAttachment: true,
          }
          res.data.map(async (val, key) => {
            obj.Message = val.name;
            obj.AttachmentType = val.type;
            if (connection.state === "Connected") {
              connection.invoke('SendMessage', obj)
            } else {
              await onLoad()
              connection.invoke('SendMessage', obj)
            }
          })
        }
      } else {
        toast.error("files must be png, jpeg, Pdf, excel, or csv and max size is 2 MB.")
      }
    }
  }

  let resizeWindow = () => {
    if (chatpanal.current && chatpanalHead.current && chatpanalFoot.current) {
      let totalheight = chatpanal.current.clientHeight;
      let headerHeight = chatpanalHead.current.clientHeight;
      let footerHeight = chatpanalFoot.current.clientHeight;
      let newheight = (totalheight - headerHeight) - footerHeight;
      setsetInboxHeight(newheight)
    }


  };
  React.useLayoutEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, [])




  return (
    <>
      <Globalstyle height={setInboxHeight} />
      <div className="container2 mx-3">
        <div className="row no-gutters wrapperHeightCalc " ref={chatpanal}>
          <div className="col-md-4 pe-0 border-end">
            {
              !!ChatList &&
              <> <div className="friend-drawer friend-drawer--onhover" onClick={() => {
                setTimeout(function () {
                  resizeWindow();
                }, 20); setActiveChat(ChatList); HandleChatClick(ChatList.senderId.toString())
              }}>
                <div className='d-flex align-items-center flex-grow-1'>
                  <img className="profile-image" src={!!ChatList.imageURL ? config.S3BucketURL + ChatList.imageURL : 'https://freesvg.org/img/abstract-user-flat-4.png'} alt="" />
                  <div className="text flex-grow-1">
                    <h6 className='m-0'>{ChatList.employeeName} - {ChatList.companyName} </h6>
                    <p className="text-muted font12">
                      {
                        ChatList.isBold ?
                          <>
                            <b>{ChatList.lastMessage}</b>
                            <DOT className='smallDOT' color="red" />
                          </>
                          :
                          ChatList.lastMessage
                      }
                    </p>
                  </div>
                </div>
                <span className="time text-muted small">{timeSince(new Date(moment(ChatList.lastMessageDateTime).format('MM/DD/YYYY h:mm:ss a') + " UTC"))}</span>
                {
                  ChatList.isActive &&
                  <DOT color="#00dba9" className='smallDOT me-0' />
                }
              </div>
                <hr />
              </>
            }
          </div>

          <div className="col-md-8  ps-0 " >
            {
              !!ActiveChat &&
              <>
                <div className="settings-tray" ref={chatpanalHead}>
                  <div className="friend-drawer no-gutters align-items-center friend-drawer--grey">
                    <div className='d-flex align-items-center flex-grow-1'>
                      <img className="profile-image" src={!!ActiveChat.imageURL ? config.S3BucketURL + ActiveChat.imageURL : 'https://freesvg.org/img/abstract-user-flat-4.png'} alt="" />
                      <div className="text flex-grow-1">
                        <h6 className='m-0 font16'>{ActiveChat.employeeName} - {ActiveChat.companyName} </h6>
                      </div>
                    </div>
                    <span className="settings-tray--right">

                      {
                        ActiveChat.isSupportAccessBlock === false &&
                        <CircleBtnWithIcon data-tooltip-id="my-tooltip" data-tooltip-content="Login as Admin" onClick={() => fnLoginasAdmin(ActiveChat.companyId)} color='#000'><RiAdminLine /><RiAdminFill className='onHover' /> </CircleBtnWithIcon>
                        // <i className="material-icons"> <a  href="javascript:;" className='btn m-1 btn-secondary btn-sm'></a>
                        // </i>
                      }
                      <RemoveCircle data-tooltip-id="my-tooltip" data-tooltip-content="End Chat" className="me-0" onClick={() => EndChat()} />
                      {/* <i className="material-icons"> <a  href="javascript:;" className='btn m-1 btn-secondary btn-sm'></a>
                      </i> */}
                    </span>
                  </div>
                </div>
                <div className="chat-panel giveHeight flex-grow-1">
                  <div className='chatBoxHeight msg-box'>
                    {
                      !!ActiveChatMessagesList && ActiveChatMessagesList.length > 0 &&
                      ActiveChatMessagesList.map((val, key) => {
                        return <>
                          {
                            val.senderId === parseInt(user.LoginUserId) ?
                              <div className="row no-gutters">
                                <div className="col-md-12 d-flex align-item-center justify-content-end">
                                  {
                                    val.isAttachment ?
                                      <div className="chat-bubble chat-bubble--right">
                                        <a target='_blank' href={config.S3BucketURL + val.message}>
                                          {
                                            val.attachmentType === "image/png" || val.attachmentType === "image/jpeg" ?
                                              <img width={150} height={150} src={config.S3BucketURL + val.message} />
                                              :
                                              val.attachmentType === "application/pdf" ?
                                                <img width={150} height={100} src='/pdf-icon.png' />
                                                :
                                                val.attachmentType === "application/vnd.ms-excel" || val.attachmentType === "text/csv" || val.attachmentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                  <img width={150} height={100} src='/excellogo.png' />
                                                  : <img width={150} height={100} src='/msdoc.png' />
                                          }
                                        </a>
                                      </div>
                                      :
                                      <div className="chat-bubble chat-bubble--right">
                                        {val.message}
                                      </div>
                                  }
                                </div>
                              </div>
                              :
                              <div className="row no-gutters">
                                <div className="col-md-12">
                                  {
                                    val.isAttachment ?
                                      <div className="chat-bubble chat-bubble--left">
                                        <a target='_blank' href={config.S3BucketURL + val.message}>
                                          {
                                            val.attachmentType === "image/png" || val.attachmentType === "image/jpeg" ?
                                              <img width={150} height={150} src={config.S3BucketURL + val.message} />
                                              :
                                              val.attachmentType === "application/pdf" ?
                                                <img width={150} height={100} src='/pdf-icon.png' />
                                                :
                                                val.attachmentType === "application/vnd.ms-excel" || val.attachmentType === "text/csv" || val.attachmentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                  <img width={150} height={100} src='/excellogo.png' />
                                                  : <img width={150} height={100} src='/msdoc.png' />
                                          }
                                        </a>
                                      </div>
                                      :
                                      <div className="chat-bubble chat-bubble--left">
                                        {val.message}
                                      </div>
                                  }
                                </div>
                              </div>
                          }
                        </>
                      })
                    }
                  </div>
                </div>
                <div className="row enterType" ref={chatpanalFoot}>
                  <div className="col-12">
                    <form onSubmit={SendMessage}>
                      <div className="chat-box-tray">
                        <div className='emojiWrapper position-relative'>
                          <CircleBtnWithIcon className="Btn lgSpace me-2" onClick={() => sethideShowEmojipicker(!hideShowEmojipicker)}><FiSmile /></CircleBtnWithIcon>
                          {
                            hideShowEmojipicker && (
                              <div className="pickerWrapperEmj left">
                                <Picker
                                  disableSearchBar="true"
                                  style={PickerDropDown}
                                  onEmojiClick={EmojiHandleClick}
                                />
                              </div>
                            )
                          }
                        </div>
                        <input type="text" value={Message} placeholder="Type your message here..." onChange={(e) => setMessage(e.target.value)} />

                        <button type='submit' className='d-none' ref={submitBtnRef}></button>
                        <div className='d-flex ms-2'>
                          <AttachmentBtn multiple={true} inputRef={fileInput} onDone={UploadFile} />
                          <CircleBtnWithIcon className='Btn me-0 ms-2' onClick={() => submitBtnRef.current.click()}><MdSend /></CircleBtnWithIcon>
                        </div>
                      </div>



                    </form>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Inbox;


const DOT = styled.span`
width:16px;
height:16px;
background-color:${props => !!props.color ? props.color : "#ccc"};
border-radius:50%;
display: inline-block;
margin-right:10px;
`


const Globalstyle = createGlobalStyle`
.font12{
  font-size:12px;
}
.smallDOT{
  width:10px !important;
  height:10px !important;
  margin-left:10px !important;
}
.font16{
  font-size:16px !important;
}
.small.time{
  font-size: 9px;

}
.add100{
  height:100% !important;
}
.container2 {
    margin: 30px auto;
    background: #fff;
    padding: 0;
    border-radius: 7px;
    height:80%;
  }
  .row.no-gutters{
    height: calc(100% - 0vh);
    }
    .chat-panel{
    overflow-y: auto;
    overflow-x: hidden;
    height: ${props => props.height !== null ? props.height + 'px' : "45vh"} ;
      }
  .addstrechg{
    display: flex;
flex-direction: column;
justify-content: stretch;
  }

  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }

  .settings-tray {
    background: #eee;
    padding: 10px 15px;
  }
  .settings-tray .no-gutters {
    padding: 0;
  }
  .settings-tray--right {
    float: right;
  }
  .settings-tray--right i {
    margin-top: 10px;
    font-size: 25px;
    color: grey;
    margin-left: 14px;
    transition: 0.3s;
  }
  .settings-tray--right i:hover {
    color: #74b9ff;
    cursor: pointer;
  }

  .search-box {
    background: #fafafa;
    padding: 10px 13px;
  }
  .search-box .input-wrapper {
    background: #fff;
    border-radius: 40px;
  }
  .search-box .input-wrapper i {
    color: grey;
    margin-left: 7px;
    vertical-align: middle;
  }

  input {
    border: none;
    border-radius: 30px;
    width: 100%;
    padding:0px 15px;
  }
  input::placeholder {
    color: #e3e3e3;
    font-weight: 300;
    margin-left: 20px;
  }
  input:focus {
    outline: none;
  }

  .friend-drawer {
    padding: 10px 15px;
    display: flex;
    vertical-align: baseline;
    background: #fff;
    transition: 0.3s ease;
  }
  .friend-drawer--grey {
    background: #eee;
  }
  .friend-drawer .text {
    margin-left: 12px;
    width: 70%;
  }
  .friend-drawer .text h6 {
    margin-top: 6px;
    margin-bottom: 0;
    font-size: 14px;

  }
  .friend-drawer .text p {
    margin: 0;
    font-szie:10px;
  }
  .friend-drawer .time {
    color: grey;
  }
  .friend-drawer--onhover:hover {
    background: #74b9ff;
    cursor: pointer;
  }
  .friend-drawer--onhover:hover p,
  .friend-drawer--onhover:hover h6,
  .friend-drawer--onhover:hover .time {
    color: #fff !important;
  }

  hr {
    margin: 0px auto;
  }

  .chat-bubble {
    padding: 10px 14px;
    background: #eee;
    margin: 10px 30px;
    border-radius: 9px;
    position: relative;
    animation: fadeIn 1s ease-in;
    display:inline-block;
    font-size:14px;
  }
  .chat-bubble:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-bottom: 0;
    margin-top: -10px;
  }
  .chat-bubble--left:after {
    left: 0;
    border-right-color: #eee;
    border-left: 0;
    margin-left: -20px;
  }
  .chat-bubble--right:after {
    right: 0;
    border-left-color: #74b9ff;
    border-right: 0;
    margin-right: -20px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
 .chat-bubble.chat-bubble--right {
    background: #74b9ff;
    color: #fff;
  }

  .chat-box-tray {
    background: #eee;
    display: flex;
    align-items: baseline;
    padding: 10px 15px;
    align-items: center;
    margin-top: 19px;
    bottom: 0;
  }
  .chat-box-tray input {
    padding: 6px 15px;
  }
  .chat-box-tray i {
    color: grey;
    font-size: 30px;
    vertical-align: middle;
  }
  .chat-box-tray i:last-of-type {
    margin-left: 25px;
  }
`