



export class Constants {
    static get roles() {
        return {
            AccountAdmin: "Account admin",
            Employee: "Employee",
            Admin: "Admin",
            WorkspaceAdmin: "Workspace admin",
            SuperAdmin: "Super Admin",
            SupportUser: "Support User"
        }
    }

    static get roleIds() {
        return {
            Employee: 1,
            Admin: 2,
            WorkspaceAdmin: 3,
            AccountAdmin: 4,
            SuperAdmin: 5,
            SupportUser: 6
        }
    }


    static get rolesDropDown() {
        return [
            { value: 'Y8pD9YJCKexw/wBf+f8nuw==', label: this.roles.Employee, text: this.roles.Employee },//1
            { value: 'VaGFaLByaSFiTpRPWnZUxg==', label: this.roles.Admin, text: this.roles.Admin },//2
            { value: 'bfAmWGqmtASVCOOyJl/M5g==', label: this.roles.WorkspaceAdmin, text: this.roles.WorkspaceAdmin },//3
            { value: 'oOCtuL7SDG9icRuY5zx+dA==', label: this.roles.AccountAdmin, text: this.roles.AccountAdmin },//4
        ];
    }



}

