import { Sidebar as ReactProSidebar } from 'react-pro-sidebar';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { DisplayStart } from '../../Helpers/Global/StyledComponents';
import Menu from './Menu';

const Sidebarbg = styled.div`
    height:100%;
    width:100%;
    position:relative;
`
const SideBarLogo = styled.img`
width:150px;
height:auto;
object-fit:contain;
margin-left:15px;
margin-bottom:15px;
`

const Sidebar = (props) => {
    const { isCollapse, setIsCollapse } = props;
    const themeColor = useSelector(state => state.app.theme.colors.bg);
    return (
        <Sidebarbg className='sideBarMenu'>

            <DisplayStart>
                {!isCollapse ?
                    <SideBarLogo src="/assets/WhiteLogo.png" />
                    :
                    <SideBarLogo style={{ height: '33px', width: 'auto' }} src="/assets/WhiteFavicon.png" />
                }
            </DisplayStart>

            <ReactProSidebar width="248px" backgroundColor={themeColor} collapsed={isCollapse}>
                <Menu />

            </ReactProSidebar>
        </Sidebarbg>
    );
}

export default Sidebar;