
import { useState } from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { ContentArea, ContentHeader, GLobalcss, MainWrapper } from "../Helpers/Global/StyledComponents";
import MainHeader from "./Header/MainHeader";
import Sidebar from "./Sidebar/Sidebar";

export const Mainlayout = () => {
    const [isCollapse, setIsCollapse] = useState(false);

    return (
        <MainWrapper className=" add100">
            <GLobalcss />
            <div className="d-flex  add100">
                <div md={2} className="pe-0 col-sidebar" >
                    <Sidebar isCollapse={isCollapse} setIsCollapse={setIsCollapse} />
                </div>
                <div md={10} className="ps-0 col-main-content flex-grow-1  add100">
                    <ContentArea className=" add100">
                        <ContentHeader>
                            <MainHeader isCollapse={isCollapse} setIsCollapse={setIsCollapse} />
                        </ContentHeader>
                        <Container fluid className="px-0  add100">
                            <Outlet />
                        </Container>
                    </ContentArea>
                </div>
            </div>
        </MainWrapper>
    );
}


