import React, { useEffect, useState } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SignInManager } from '../../Helpers/Authentication/SignInManager';
import { AvatarBox, CollapseMenu, DisplayBetween, HeaderLeftLabel, HeaderMenu, HeaderMenuItem } from '../../Helpers/Global/StyledComponents';
import { CallAxios } from '../../Helpers';
import TblSwitchToogleButton from '../../Components/TblSwitchToogleButton';
import { config } from '../../Helpers/Config';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Constants } from '../../Helpers/Contents';




function MainHeader(props) {
    const { isCollapse, setIsCollapse } = props;

    const user = useSelector(s => s.app.user);
    const navigate = useNavigate();
    const [isOnline, setisOnline] = useState(false);
    const [connection, setConnection] = useState(null);

    useEffect(() => {
        if (!!user.EncLoginUserId) {
            if (user.roles.some(x => x === Constants.roles.SupportUser)) {
                onLoad()
            }
            GteDtata()
        }
    }, [user]);
    const GteDtata = async () => {
        var res = await CallAxios("api/AdminPanel/Employee/GetEmployeeOnlineStatus|post", { id: user.EncLoginUserId })
        setisOnline(res.data)
    }

    const ChangeStatus = async (val, status) => {
        var res = await CallAxios("api/AdminPanel/Employee/ChangeEmployeeOnlineStatus|post", { id: user.EncLoginUserId })
        setisOnline(res.data)
        if (res.data) {
            debugger
            if (connection.state === "Connected") {
                connection.invoke("AssignChatToSupportUser")
            }
        }
    }
    const onLoad = async () => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(config.API_URL + '/supportChatHub', { accessTokenFactory: () => localStorage.getItem('token') })
            .withAutomaticReconnect()
            .build();
        newConnection.start()
            .then(result => {
                newConnection.invoke("AssignChatToSupportUser")
                setConnection(newConnection);
            })
            .catch(e => console.log('Connection failed: ', e));


    }
    return (
        <>
            <DisplayBetween>
                <HeaderLeftLabel>
                    <CollapseMenu className='me-3' onClick={() => setIsCollapse(!isCollapse)}>
                        {isCollapse ?
                            <BsChevronDoubleRight size={20} />
                            :
                            <BsChevronDoubleLeft size={20} />
                        }

                    </CollapseMenu>
                    Human Resource Management System
                </HeaderLeftLabel>
                <HeaderMenu>
                    {
                        user.roles.some(x => x === Constants.roles.SupportUser) &&
                        <HeaderMenuItem>
                             <div className='d-flex align-items-center'>
                             <span className='me-2'>{isOnline ? "Online" : "Offline"}</span>
                            <TblSwitchToogleButton status={isOnline} val={isOnline} onClicksubmit={ChangeStatus} />
                            </div>
                        </HeaderMenuItem>
                    }
                    <HeaderMenuItem>
                        <NavDropdown
                            id="nav-dropdown-dark-example"
                            title={<UserAvatar Image="" Username={user.empName} />}
                        >
                            {/* <NavDropdown.Divider /> */}
                            <NavDropdown.Item href="" onClick={(e) => { e.preventDefault(); new SignInManager().Logout(navigate) }}>
                                Logout
                            </NavDropdown.Item>
                        </NavDropdown>
                    </HeaderMenuItem>
                </HeaderMenu>
            </DisplayBetween>
        </>
    );
}

export default MainHeader;


const UserAvatar = (props) => {
    const { Image, Username } = props;
    const UsernameShort = Username.split(' ').map(word => word.charAt(0).toUpperCase())
    return (
        <>
            <AvatarBox bgImage={Image}>{!!Image ? null : UsernameShort}</AvatarBox> {Username}
        </>
    )
}
