import { HubConnectionBuilder } from '@microsoft/signalr';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Route, Routes, useLocation, useNavigate
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { ThemeProvider } from 'styled-components';
import { Forbidden401 } from '../Components/Forbidden401';
import { Forbidden403 } from '../Components/Forbidden403';
import Loader from '../Components/Loader';
import PageNotFound from '../Components/PageNotFound';
import { SignInManager } from '../Helpers/Authentication/SignInManager';
import { config } from '../Helpers/Config';
import { Constants } from '../Helpers/Contents';
import { HooksHelper } from '../Helpers/HooksHelper';
import { UpdateReduxStates } from '../Helpers/MasterData';
import { Mainlayout } from '../Layout/Mainlayout';
import Invites from '../Pages/Accounts/Invites';
import { Login } from '../Pages/Accounts/Login';
import { ResetPassword } from '../Pages/Accounts/ResetPassword';
import Inbox from '../Pages/Inbox';
import { setmessgaeCounter } from '../Store/slices/AppSlice';
import './App.css';
import TicketsList from '../Pages/Tickets/TicketsList';

export const App = () => {
  const curLoc = useLocation();

  const titlePrefix = ' | Basta-Shift';
  const jwt = useSelector(s => s.app.jwt);
  const themeColor = useSelector(state => state.app.theme);

  const dispatch = useDispatch();
  HooksHelper.navigate = useNavigate();
  HooksHelper.dispatch = dispatch;
  useEffect(() => {
    if (!!jwt.token && jwt.token !== ".") {
      UpdateReduxStates(new SignInManager().ParseJwt, dispatch)
      if (new SignInManager().ParseJwt.Role.split(",").some(x => x === Constants.roles.SupportUser)) {
        connectHub(new SignInManager().ParseJwt.EmpID)
      }
    }
  }, [jwt.token]);

  const connectHub = async (EmpID) => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(config.API_URL + '/supportChatHub', { accessTokenFactory: () => localStorage.getItem('token') })
      .withAutomaticReconnect()
      .build();
    newConnection.start()
      .then(result => {
        newConnection.on("unread_messgae_counter", (data) => {
          dispatch(
            setmessgaeCounter(data)
          )
        });
        newConnection.invoke("UnreadMessgaeCounter", EmpID)
      })
      .catch(e => console.log('Connection failed: ', e));

  }


  const titleMap = [
    { path: '/', title: 'Site' },
    { path: '/login', title: 'Login' },
    { path: '/inbox', title: 'Inbox' },
  ];
  useEffect(() => {
    const curTitle = titleMap.find(item => item.path.startsWith(curLoc.pathname.toLowerCase()))
    if (curTitle && curTitle.title) {
      document.title = curTitle.title + titlePrefix
    } else {
      document.title = 'Default' + titlePrefix
    }
  }, [curLoc, titleMap])
  const isAuthenticated = new SignInManager().IsAuthenticated;

  return (
    <div className="App  add100">
      <ThemeProvider theme={themeColor}>
        <Loader />
        <ToastContainer limit={2} newestOnTop theme='light' />
        <Tooltip id="my-tooltip" />
        <Tooltip id="my-tooltip-click" openOnClick={true} />
        <Routes>
          {
            isAuthenticated ?
              <Route element={<Mainlayout />}>
                <Route path='/' element={<ProtectedRoute role={[Constants.roles.SupportUser]}><Inbox /></ProtectedRoute>} />
                <Route path='inbox' element={<ProtectedRoute role={[Constants.roles.SupportUser]}><Inbox /></ProtectedRoute>} />
                <Route path='tickets' element={<ProtectedRoute role={[Constants.roles.SupportUser]}><TicketsList /></ProtectedRoute>} />
              </Route>
              :
              <Route>
                <Route path='/' element={<ProtectedRoute><EmptyRoute /></ProtectedRoute>}> </Route>
              </Route>

          }

          {/* <Route path='/' element={<CheckAuthentication><Login /></CheckAuthentication>} /> */}
          <Route path='/login' element={<CheckAuthentication><Login /></CheckAuthentication>} />
          <Route path='/Invites' element={<Invites />} />
          <Route path='/Passwordreset' element={<ResetPassword />} />

          <Route path="/forbidden401" element={<Forbidden401 />} />
          <Route path="/forbidden403" element={<Forbidden403 />} />
          <Route path="*" element={<PageNotFound />} />

        </Routes>

      </ThemeProvider>
    </div >
  );
}
function EmptyRoute() {
  return <></>
}
function CheckAuthentication(props) {
  const { children } = props;
  var navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = new SignInManager().IsAuthenticated;
  const ParseJwt = new SignInManager().ParseJwt;
  if (isAuthenticated) {
    UpdateReduxStates(ParseJwt, dispatch)
    navigate(`/inbox`)
  } else {
    return children;
  }
}

export function ProtectedRoute(props) {
  const { children } = props;
  const location = useLocation();
  const isAuthenticated = new SignInManager().IsAuthenticated;
  const role = new SignInManager().UserRole;
  if (!isAuthenticated || (props.role && role && !props.role.some(x => role.split(",").includes(x)))) {
    const rUri = location.pathname;
    return <Navigate to={'/login?redirect_url=' + rUri} replace />;
  }
  return children;
};
