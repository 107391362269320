import axios from 'axios';
import { toast } from 'react-toastify';
import { setisLoading } from '../../Store/slices/AppSlice';
import { SignInManager } from '../Authentication/SignInManager';
import { config } from '../Config';
import { HooksHelper } from '../HooksHelper';


axios.defaults.baseURL = config.API_URL;

export const AxiosLite =
    async (url, params, navigate, dispatch, shouldAttachToken = true) => {
        return CallAxios(url, undefined, params, undefined, navigate, dispatch, shouldAttachToken);
    };

export const CallAxiosBlob =
    async (url, body) => {
        return CallAxios(url, body, undefined, undefined, undefined, undefined, true, 'blob');
    };

export const CallAxios = async (url, body, params, headers, navigate, dispatch, shouldAttachToken = true, responseType = 'json') => {
    if (url) console.log('URL: ', url);
    if (body) console.log('Body: ', body);
    if (params) console.log('Params: ', params);


    const _dispatch = dispatch ?? HooksHelper.dispatch;
    if (_dispatch) _dispatch(setisLoading(true))

    try {

        const res = await axios.request({
            url: url.split('|')[0],
            method: url.split('|')[1],
            params,
            headers: shouldAttachToken ? { ...headers, Authorization: ('Bearer ' + new SignInManager().AccessToken) } : { ...headers },
            data: body,
            responseType: responseType
        });
        console.log('Response: ', res);
        if (_dispatch) _dispatch(setisLoading(false))
        return res;
    } catch (err) {
        console.error(err, err.response);
        if (_dispatch) _dispatch(setisLoading(false))
        if (err.response) {
            const _navigate = navigate ?? HooksHelper.navigate;
            if (err.response.status === 401) {
                const rUri = window.location.pathname
                    .replace('login', '');
                if (_navigate) {
                    _navigate('/login?redirect_url=' + rUri);
                }
            }
            else if (err.response.status === 403) {
                if (_navigate)
                    _navigate('/forbidden403');
            }
            else {
                toast.error("Something went wrong.")
            }
        }
    }
};

// type API_URLs = keyof DataReturnTypes;


