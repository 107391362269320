
import { useFormik } from 'formik';
import React from 'react';
import { AiFillEye } from "react-icons/ai";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { CallAxios } from '../../Helpers';
import { AnchorLink, ButtonWrapper, DisplayFlex, DisplayFlexBetween, FormGroup, InputField, InputIcon, LoginContent, LoginLogo, LoginWrapper, PrimaryButton, Title, TitleWrapper } from '../../Helpers/Global/StyledComponents';

export const ResetPassword = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const formik = useFormik({
        initialValues: {
            password: '',
            confirmpassword: '',
        },
        validationSchema: Yup.object().shape({
            password: Yup.string().required("Password is required"),
            confirmpassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Password and confirm password not match')
        }),
        onSubmit: values => {
            submit(values)
        },
    })
    const submit = async (values) => {
        if (!!token) {
            var data = {
                Code: token,
                // Email: email,
                password: values.password
            }
            var response = await CallAxios("/api/Account/resetpassword|post", data);
            if (!!response && response.status === 200) {
                if (response.data === true) {
                    toast.success("Password update successfully")
                    navigate("/login")
                }
                else if (response.data === "expire") {
                    toast.error("Token is expired")
                } else {
                    toast.error("Something went wrong")
                }
            }
        } else {
            toast.error("Token is expired")
        }

    }
    return (
        <>
            <LoginWrapper>
                <LoginContent>
                    <LoginLogo src="/assets/logoPlaceholder.png" />
                    <TitleWrapper>
                        <Title>Reset password</Title>
                    </TitleWrapper>
                    <FormGroup>
                        <InputField Password={true} name='password' value={formik.values.password} placeholder="Password" autoFocus="autofocus" onChange={formik.handleChange} />
                        <InputIcon className='input-icon'>
                            <AiFillEye size={20} color={"#cbcbcb"} />
                        </InputIcon>
                        {formik.touched.password && formik.errors.password ? (
                            <span className="error text-danger text-left">{formik.errors.password}</span>
                        ) : null}
                    </FormGroup>
                    <FormGroup>
                        <InputField Password={true} name='confirmpassword' value={formik.values.confirmpassword} placeholder="Confirm Password" onChange={formik.handleChange} />
                        <InputIcon className='input-icon'>
                            <AiFillEye size={20} color={"#cbcbcb"} />
                        </InputIcon>
                        {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
                            <span className="error text-danger text-left">{formik.errors.confirmpassword}</span>
                        ) : null}
                    </FormGroup>
                    <DisplayFlexBetween>
                        <DisplayFlex>
                            <AnchorLink onClick={() => navigate("/login")}>Go to login</AnchorLink>
                        </DisplayFlex>
                    </DisplayFlexBetween>
                    <ButtonWrapper>
                        <PrimaryButton onClick={formik.handleSubmit}>Update password</PrimaryButton>
                    </ButtonWrapper>
                </LoginContent>
            </LoginWrapper>
        </>
    )
}



