


export class HooksHelper {
    static _navigate = undefined;
    static get navigate() {
        return this._navigate;
    }
    static set navigate(val) {
        this._navigate = val;
    }

    static _dispatch = undefined;
    static get dispatch() {
        return this._dispatch;
    }
    static set dispatch(val) {
        this._dispatch = val;
    }
}