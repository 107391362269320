import { createSlice } from '@reduxjs/toolkit';
import { SignInManager } from '../../Helpers/Authentication/SignInManager';

// Redux States
const initialState = {
  activeNavBar: 'Design',
  isLoading: false,
  messgaeCounter: 0,
  theme: {
    colors: {
      bg: "#1b9176",
      buttons: "#01dba9",
      headerText: "#109a7a",
      active: "#00dba9"
    }
  },
  jwt: {
    token: new SignInManager().AccessToken
  },
  user: {
    roles: [],
    LoginUserId: "",
    EncLoginUserId: "",
    empName: "",
  },

};

//Slice
export const AppSlice = createSlice({
  name: 'AppSlice',
  initialState,
  reducers: {
    setAddEditResponseWindow: (state, action) => {
      state.activeNavBar = action.payload;
    },
    setisLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setmessgaeCounter: (state, action) => {
      state.messgaeCounter = action.payload;
    },
    setJwt: (state, action) => {
      state.jwt = { ...state.jwt, ...action.payload };
    },
    setUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
  },
})

// Exporter
export const {
  setAddEditResponseWindow,
  setisLoading,
  setJwt,
  setUser,
  setmessgaeCounter
} = AppSlice.actions

export default AppSlice.reducer