import React, { useState, useEffect } from 'react';
import { FallingLines } from 'react-loader-spinner';
import { useSelector } from 'react-redux';

const Loader = () => {
    const isLoading = useSelector(x => x.app.isLoading);
    return (
        <>
            {
                isLoading &&
                <div className="fullLoader">
                    <FallingLines
                        color="#4fa94d"
                        width="100"
                        visible={true}
                        ariaLabel='falling-lines-loading'
                    />
                </div>
            }
        </>
    );
}

export default Loader;