import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { AiFillEye, AiFillEyeInvisible, AiTwotoneMail } from "react-icons/ai";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { CallAxios } from '../../Helpers';
import { SignInManager } from '../../Helpers/Authentication/SignInManager';
import { AnchorLink, ButtonWrapper, DisplayFlex, DisplayFlexBetween, FormGroup, InputField, InputIcon, LoginContent, LoginLogo, LoginWrapper, PrimaryButton, SubTitle, Title, TitleWrapper } from '../../Helpers/Global/StyledComponents';
import { setJwt, setUser } from '../../Store/slices/AppSlice';
import { Constants } from '../../Helpers/Contents';

export const Login = () => {
    const [showRestPasswordModal, setShowRestPasswordModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const login = async (values) => {
        var res = await new SignInManager().Login(values.email, values.password)
        if (res.status === 200) {

            if (!!res.data.message) {
                toast.error(res.data.message)
            }
            else {
                new SignInManager().SetToken(res.data.token)
                dispatch(
                    setUser({
                        roles: res.data.userRoles.split(","),
                        LoginUserId: res.data.employeeId.toString(),
                    })
                );
                localStorage.setItem("InitialLogin", true) //use this flag to set all workareas and tags seleted by default in redux
                dispatch(setJwt({ token: res.data.token }));
                if (res.data.userRoles.split(",").some(x => x === Constants.roles.SuperAdmin)) {
                    navigate(`/companies`)
                } else {
                    navigate(`/inbox`)
                }
            }
        }
    }
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email("Invalid email address")
                .required("Please enter email"),
            password: Yup.string().required("Required")
        }),
        onSubmit: login
    })
    return (
        <>
            {
                showRestPasswordModal &&
                <ResetPassword show={showRestPasswordModal} setShow={setShowRestPasswordModal} />
            }
            <LoginWrapper>
                <LoginContent>
                    <LoginLogo src="/assets/logoPlaceholder.png" />
                    <TitleWrapper>
                        <SubTitle>Welcome to</SubTitle>
                        <Title>Basta Shift</Title>
                        <SubTitle>Admin Pannel</SubTitle>
                    </TitleWrapper>
                    <form onSubmit={formik.handleSubmit}>
                        <FormGroup>
                            <InputField type="text" name='email' value={formik.values.email} placeholder="E-Mail" autoFocus="autofocus" onChange={formik.handleChange} />
                            <InputIcon className='input-icon'>
                                <AiTwotoneMail size={20} color={"#cbcbcb"} />
                            </InputIcon>
                            {formik.touched.email && formik.errors.email ? (
                                <span className="error text-danger text-left">{formik.errors.email}</span>
                            ) : null}
                        </FormGroup>
                        <FormGroup>
                            <InputField type={showPassword === true ? "text" : "password"} Password={true} name='password' value={formik.values.password} placeholder="Password" onChange={formik.handleChange} />
                            <InputIcon className='input-icon'>
                                {showPassword === false ? <AiFillEye size={20} onClick={() => setShowPassword(true)} color={"#cbcbcb"} /> : <AiFillEyeInvisible onClick={() => setShowPassword(false)} size={20} color={"#cbcbcb"} />}

                            </InputIcon>
                            {formik.touched.password && formik.errors.password ? (
                                <span className="error text-danger text-left">{formik.errors.password}</span>
                            ) : null}
                        </FormGroup>
                        <DisplayFlexBetween>
                            <DisplayFlex>
                                <AnchorLink onClick={() => setShowRestPasswordModal(true)}>Forgot Password  </AnchorLink>
                                {/* <AnchorLink onClick={() => navigate("/register")}> Join Now!</AnchorLink> */}
                            </DisplayFlex>
                            {/* <FormCheck>
                            <CheckBox type="checkbox" />
                            <FormCheckLabel>Remember</FormCheckLabel>
                        </FormCheck> */}
                        </DisplayFlexBetween>
                        <ButtonWrapper>
                            <PrimaryButton type='submit'>Sign In</PrimaryButton>
                        </ButtonWrapper>
                    </form>
                </LoginContent>
            </LoginWrapper>
        </>
    )
}


function ResetPassword(props) {
    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email("Invalid email address")
                .required("Please enter email")
        }),
        onSubmit: values => {
            sendResetPasswordEmail(values)
        },
    });
    const sendResetPasswordEmail = async (values) => {
        var data = {
            UserName: values.email
        }
        var response = await CallAxios("/api/AdminPanel/SupportUser/sendresetpasswordemail|post", data)
        // var response = await dynamicPostApi(data, "/api/Account/sendresetpasswordemail")
        if (!!response && response.status === 200) {
            if (response.data === "NotFound") {
                toast.info("E-mail address not known")
            }
            else if (response.data === true) {
                toast.success("The E-Mail to reset the password was sent.")
                props.setShow(false);
            }
        }
    }
    return (
        <>
            <Modal show={props.show} onHide={() => props.setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Reset password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <InputField type="text" name='email' value={formik.values.email} placeholder="E-Mail" autoFocus="autofocus" onChange={formik.handleChange} />
                        <InputIcon className='input-icon'>
                            <AiTwotoneMail size={20} color={"#cbcbcb"} />
                        </InputIcon>
                        {formik.touched.email && formik.errors.email ? (
                            <span className="error text-danger text-left">{formik.errors.email}</span>
                        ) : null}
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => props.setShow(false)}>
                        Close
                    </Button>
                    <Button variant="success" onClick={formik.handleSubmit}>
                        Send
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


