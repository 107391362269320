import { useEffect } from "react";
import { useState } from "react";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { CircleBtnWithIcon } from "../Helpers/Global/StyledComponents";
import { useSelector } from "react-redux";

export default function TblSwitchToogleButton(props) {
    const [btnActiveState, setActive] = useState(false);
    const themeColor = useSelector(state => state.app.theme.colors.bg);
    const toggleClass = () => {
        setActive(!btnActiveState);
        if (!!props.checkactive) {
            props.checkactive(!btnActiveState)
        }
        if (!!props.onClicksubmit) {
            props.onClicksubmit(props.val, !btnActiveState);
        }
    };
    useEffect(() => {
        setActive(props.status)
    }, [props.status]);
    return <>
        <a className={btnActiveState ? 'btnTblAction bgGreen active' : 'btnTblAction'} onClick={toggleClass}>
            {btnActiveState ? <CircleBtnWithIcon color={themeColor} className="Btn"><BsToggleOn /></CircleBtnWithIcon> : <CircleBtnWithIcon color={themeColor} className="Btn"><BsToggleOff /></CircleBtnWithIcon>}
        </a>
    </>
};
