import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { BsFillInboxFill } from 'react-icons/bs';
import { HiOutlineUserGroup } from "react-icons/hi";
import { Menu as ReactProMenu, MenuItem as ReactProMenuItem, menuClasses } from 'react-pro-sidebar';
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import styled from 'styled-components';
import { Constants } from '../../Helpers/Contents';
import { SignInManager } from '../../Helpers/Authentication/SignInManager';
import { IoTicketOutline } from 'react-icons/io5';
import { MdOutlineBusiness } from 'react-icons/md';
const Menubox = styled(ReactProMenu)`
margin-top: 20px;
`;
const Menuitem = styled(NavLink)`
    display: block;
    text-decoration: none;
    color: #eee;
    cursor: pointer;
    text-align:left;
    // border-bottom:1px solid #8dc8bb;
    &:hover{
        color:#fff !important;
    }
    &.active .menu-item-box{
        background-color: ${props => props.active};
        border-radius: 12px 0px 0px 12px;
        color: #fff !important;
    }
`;
const Menuitemicon = styled.span`
display: inline-block;
    margin-right: 10px;
`;



const Menu = () => {

    var messgaeCounter = useSelector(x => x.app.messgaeCounter)
    const themeColorActive = useSelector(state => state.app.theme.colors.active);
    const jwt = useSelector(state => state.app.jwt);

    const [menuList, setMenuList] = useState([]);
    const [role, setRole] = useState("");
    useEffect(() => {
        if (!!jwt) {
            const ParseJwt = new SignInManager().ParseJwt;
            setRole(ParseJwt.Role)
        }
    }, [jwt]);

    const handleMenuItemClick = (index) => {
        var tempList = [...menuList];
        for (var i = 0; i < tempList.length; i++) {
            tempList[i].isActive = false;
        }
        tempList[index].isActive = true;
        setMenuList(tempList)
    }


    return (
        <>
            <Menubox
                menuItemStyles={{
                    button: {
                        // the active class will be added automatically by react router
                        // so we can use it to style the active menu item
                        [`&.active`]: {
                            backgroundColor: themeColorActive,
                            color: '#fff',

                        },
                    },
                }}
            >
                {/* {menuList.map((item, index) => {
                    return <>
                        <ReactProMenuItem
                            rootStyles={{
                                ['.' + menuClasses.button]: {
                                    backgroundColor: 'transparent',
                                    color: '#fff !important',
                                    borderTopLeftRadius: '15px',
                                    borderBottomLeftRadius: '15px',
                                    marginTop: "10px",
                                    '&:hover': {
                                        backgroundColor: `${themeColorActive} !important`,
                                    },
                                },
                            }}
                            icon={
                                <Menuitemicon className='menu-item-icon'>
                                    {item.icon}
                                </Menuitemicon>
                            }
                            component={<Menuitem active={themeColorActive} to={item.link} onClick={() => handleMenuItemClick(index)} />}
                        >
                            {item.linkTex}
                        </ReactProMenuItem>
                    </>
                })} */}
                <ReactProMenuItem
                    rootStyles={{
                        ['.' + menuClasses.button]: {
                            backgroundColor: 'transparent',
                            color: '#fff !important',
                            borderTopLeftRadius: '15px',
                            borderBottomLeftRadius: '15px',
                            marginTop: "10px",
                            '&:hover': {
                                backgroundColor: `${themeColorActive} !important`,
                            },
                        },
                    }}
                    icon={
                        <Menuitemicon className='menu-item-icon'>
                            <BsFillInboxFill size={17} />
                        </Menuitemicon>
                    }
                    component={<Menuitem active={themeColorActive} to="/inbox" />}
                // component={<Menuitem active={themeColorActive} to="/inbox" onClick={() => handleMenuItemClick(index)} />}
                >
                    Inbox
                    {
                        messgaeCounter > 0 &&
                        <Badge style={{ "marginLeft": "10px" }} bg="danger">{messgaeCounter}</Badge>
                    }

                </ReactProMenuItem>
                <ReactProMenuItem
                    rootStyles={{
                        ['.' + menuClasses.button]: {
                            backgroundColor: 'transparent',
                            color: '#fff !important',
                            borderTopLeftRadius: '15px',
                            borderBottomLeftRadius: '15px',
                            marginTop: "10px",
                            '&:hover': {
                                backgroundColor: `${themeColorActive} !important`,
                            },
                        },
                    }}
                    icon={
                        <Menuitemicon className='menu-item-icon'>
                            <BsFillInboxFill size={17} />
                        </Menuitemicon>
                    }
                    component={<Menuitem active={themeColorActive} to="/tickets" />}
                // component={<Menuitem active={themeColorActive} to="/inbox" onClick={() => handleMenuItemClick(index)} />}
                >
                    Tickets
                </ReactProMenuItem>

            </Menubox>
        </>
    );
}


export default Menu;


