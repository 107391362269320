// import { AxiosLite } from "../../../Basta_Shift_Web/src/Helpers/API/useAxios";
import { AxiosLite } from "../Helpers/API/useAxios";
import { setUser } from "../Store/slices/AppSlice";



export async function UpdateReduxStates(tokenData, dispatch) {
    localStorage.setItem("LoginUserId", tokenData.EmpID)
    dispatch(
        setUser({
            roles: tokenData.Role.split(","),
            LoginUserId: tokenData.EmpID,
            EncLoginUserId: tokenData.EncLoginUserId,
            empName: tokenData.EmpName,
        })
    );
    localStorage.setItem("InitialLogin", false)
}